/**

=========================================================
** General Variables
=========================================================

**/


// Font Sizes Declaration
// Base size is 16px

// Declared on Tailwind Config

// $h1-size: rem(56);
// $h2-size: rem(36);
// $h3-size: rem(30);
// $h4-size: rem(24);
// $h5-size: rem(20);
// $h6-size: rem(18);
// $base-size: rem(16);
// $small-size: rem(14);
// $mini-size: rem(12);

$title-font : "Inter", sans-serif;
$body-font : "Inter", sans-serif;

