/**

=========================================================
** Tailwind imports styling
=========================================================

**/

@tailwind base;
@tailwind components;
@tailwind utilities;
