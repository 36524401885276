/**

=========================================================
** Custom Button styling
=========================================================

**/

@layer components {
    .btn {
        @apply inline-flex w-full cursor-pointer items-center justify-center gap-3 rounded-lg px-8 py-2 font-semibold  text-black transition-colors duration-300 hover:bg-secondary-500 md:w-auto;
    }
    .btn--primary {
        @apply bg-primary-500;
    }
    .btn--secondary {
        @apply bg-secondary-500;
    }
    .btn--outline {
        @apply border border-[1px] border-black bg-white text-black hover:text-white;
    }
    .btn--black {
        @apply bg-black-500;
    }

    /**
  =========================================================
  ** Button Groups **
  =========================================================
  **/

    .btn-group {
    }
    .btn-group--container {
        @apply flex flex-wrap gap-4;
    }
}
