/**

=========================================================
** Custom General styling
=========================================================

**/

html {
}

@layer base {
    html {
        scroll-behavior: smooth;
    }
}

body {
    font-family: $body-font;
    letter-spacing: rem(-0.5);
}

.main {
    // @apply grid gap-8 sm:gap-16;
}

#gradient-canvas {
    width: 100%;
    height: 100%;
    --gradient-color-1: #c3e4ff;
    --gradient-color-2: #6ec3f4;
    --gradient-color-3: #eae2ff;
    --gradient-color-4: #b9beff;
}

.content {
    &.text-xl {
        p {
            @apply leading-7;
        }
    }
}
