/**

=========================================================
** Custom Card styling
=========================================================

**/

@layer components {
    .card {
        @apply text-black/60;
    }
    .card--container {
        @apply flex h-full flex-wrap;
    }
    .card-group--container {
        @apply grid w-full grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3;
    }
    .card--header {
        @apply mt-8 mb-2 flex w-full items-center justify-between;
        h2 {
            @apply mb-0 text-xl;
        }
    }
}
