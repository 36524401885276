/*

=========================================================
* Avenue CSS Kit v0.1
=========================================================

* Demo Page: 'https://nutritrack.avenuelabs.co/'
* Copyright 2023 Christian Luntok 
* Coded by Christian Luntok

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Core CSS Reset/MISCS
@import "core/misc";

// Core Declarations
@import "core/variables";

// Core Layouts and Style Definitions
@import "core/general";
@import "core/typography";
@import "core/utilities";

// Core Components
@import "core/components/button";
@import "core/components/badge";
@import "core/components/nav";
@import "core/components/header";
@import "core/components/card";
@import "core/components/page-banner";
@import "core/components/footer";

.has-text-align-left {
  @apply text-left;
}

.has-text-align-center {
  @apply text-center;
}

.has-text-align-right {
  @apply text-right;
}

.has-large-font-size {
  @apply text-4xl;
}

.alignfull {
  @apply w-screen relative;

  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: 100vw;
  right: 50%;
}

.wp-block-image img {
  @apply max-w-full mt-2;
}
.wp-block-image.aligncenter {
  @apply text-center;
}
.wp-block-image.alignfull img,
.wp-block-image.alignwide img {
  @apply w-full;
}
.wp-block-image .alignleft,
.wp-block-image .alignright,
.wp-block-image .aligncenter,
.wp-block-image.is-resized {
  @apply table ml-0 mr-0;
}
.wp-block-image .alignleft > figcaption,
.wp-block-image .alignright > figcaption,
.wp-block-image .aligncenter > figcaption,
.wp-block-image.is-resized > figcaption {
  @apply table-caption;

  caption-side: bottom;
}

.wp-block-image .alignleft {
  @apply float-left mr-4;
}
.wp-block-image .alignright {
  @apply float-right ml-4;
}
.wp-block-image .aligncenter {
  @apply m-auto;
}

.wp-block-button a,
.wp-block-file a.wp-block-file__button {
  @apply bg-blue-500 text-white no-underline py-2 px-4;
}

.wp-block-button a:hover,
.wp-block-file a.wp-block-file__button:hover {
  @apply bg-blue-400 cursor-pointer;
}

.wp-block-file a:first-of-type {
  @apply mr-4;
}

.wp-block-cover {
  @apply flex flex-wrap justify-center items-center bg-cover bg-center overflow-hidden;

  min-height: 430px;
}

.wp-block-verse {
  @apply font-sans;
}

.wp-block-media-text {
  @apply grid grid-cols-2 gap-4;
}

// React Grid Gallery

.ReactGridGallery_tile-viewport {
  border-radius: 8px;
}

// Custom Shape Divider

.custom-shape-divider-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 50px;
}

.custom-shape-divider-bottom .shape-fill {
  fill: #FFFFFF;
}